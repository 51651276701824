import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { SIDEBAR_WIDTH } from 'components/Page/PageSideBar/PageSideBar';
import { MenuItem } from 'components/Page/PageSideBar/PageSideBar.styled';
import Megamenu from 'components/Page/PageSideBar/SideBarCarCatalog/Megamenu';
import { TextWrapper } from 'components/ProfileInfo/ProfileInfo';
import { formatCategoryLabelToDataCy, getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { theme } from 'styles';
import { Box, Flex, Modal, Text } from 'UI';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';

export interface PartsCatalogType {
  treeData: VehiclePartCategoryTreeItem[] | VehicleIAMPartCategoryTreeItem[];
  vehicleDetail: VehicleDetail;
}

export interface CarPartsSubmenuCategoriesType extends PartsCatalogType {
  level1Label: string;
  level1NodeId: string;
  setActiveCategory: (s: string) => void;
}

const PartsCatalog = ({ treeData, vehicleDetail }: PartsCatalogType) => {
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState<string>('');
  const history = useHistory();

  const offsetTop = useOffsetTop('HOME_PAGE');

  const handleLevel1Click = (nodeId: string) => {
    const link = `${getCatalogSourceUrl(vehicleDetail.catalogSource)}/${
      vehicleDetail.vehicleKey
    }${ROUTER_CATALOG_VEHICLE}/${nodeId}`;
    handleCloseModal();
    history.push(link);
  };

  const handleCloseModal = () => {
    setActiveCategory('');
  };

  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const handleMouseEnter = (activeDirectory: string) => {
    const timeout = setTimeout(() => {
      setActiveCategory(activeDirectory);
    }, 100);
    setHoverTimeout(timeout);
  };
  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setHoverTimeout(undefined);
  };

  const calculateWidth = () => {
    const activeLevel1Item = treeData.find((level1) => level1.nodeId === activeCategory);
    const subcategoryCount = activeLevel1Item?.items.length;
    const space = window.innerWidth - SIDEBAR_WIDTH;
    const cardWidth = 332;
    const modalPadding = 168;
    const fitCount = Math.floor(space / cardWidth);
    if (subcategoryCount && fitCount > subcategoryCount) {
      return cardWidth * subcategoryCount + modalPadding;
    }
    return cardWidth * fitCount + modalPadding;
  };

  const [minModalHeight, setMinModalHeight] = useState<number | undefined>(undefined);

  return (
    <>
      <Flex direction={'column'} dataCy={'parts-catalog'}>
        <Text type={'h5_bold'}>{t('catalog.catalog_parts.title', 'Parts Catalog')}</Text>
        {treeData.map((level1) => {
          return (
            <MenuItem
              ref={(element) => {
                if (activeCategory === level1.nodeId) {
                  const rect = element?.getBoundingClientRect();
                  const minHeight = rect && rect.top + window.scrollY - offsetTop;
                  setMinModalHeight(minHeight);
                }
              }}
              key={level1.nodeId}
              $hoverActive={activeCategory === level1.nodeId}
              submenu
              onClick={() => handleLevel1Click(level1.nodeId)}
              onMouseEnter={() => handleMouseEnter(level1.nodeId)}
              onMouseLeave={handleMouseLeave}
              data-cy={formatCategoryLabelToDataCy(level1.label, 'tab')}
            >
              <TextWrapper maxHeight={44}>
                <Text type={'text_dim'} transform={'capitalize'}>
                  {level1.label}
                </Text>
              </TextWrapper>
            </MenuItem>
          );
        })}
      </Flex>

      <Modal
        title={null}
        footer={null}
        mask={false}
        maskClosable={true}
        open={activeCategory !== ''}
        onCancel={handleCloseModal}
        zIndex={1000}
        backgroundColor={theme.color.grey95_light_120}
        style={{
          position: 'absolute',
          top: offsetTop,
          left: SIDEBAR_WIDTH,
          minWidth: calculateWidth(),
        }}
      >
        <Box onMouseLeave={handleCloseModal}>
          <Flex minHeight={minModalHeight} wrap={'wrap'}>
            {treeData.map((level1) => {
              return (
                activeCategory === level1.nodeId && (
                  <Megamenu
                    vehicleDetail={vehicleDetail}
                    treeData={level1.items}
                    level1Label={level1.label}
                    level1NodeId={level1.nodeId}
                    setActiveCategory={setActiveCategory}
                  />
                )
              );
            })}
          </Flex>
        </Box>
      </Modal>
    </>
  );
};

export default PartsCatalog;
