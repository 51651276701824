import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import { TFunction } from 'i18next';
import {
  ROUTER_ACCOUNT,
  ROUTER_ESTIMATE,
  ROUTER_HISTORY,
  ROUTER_HOME,
  ROUTER_ORDERS,
  ROUTER_PROFILE,
} from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { ClockIcon, FileAltIcon, FolderOpenRegularIcon, HomeIcon, UserIcon } from 'assets/icons';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { getLinkItems } from 'components/Page/Header/TopHeader/Links/LinkTabs';
import { HEADER_HEIGHT } from 'components/Page/Header/TopHeader/TopHeader';
import SideBarCarCatalog from 'components/Page/PageSideBar/SideBarCarCatalog';
import ProfileInfo from 'components/ProfileInfo';
import PrivateComponent from 'composers/PrivateComponent';
import { getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { getCurrentEstimateId, getRowTablesCount } from 'domains/estimate/Estimate.store';
import { EstimateTabName, EstimateTabParam } from 'domains/estimate/Estimate.types';
import { getCurrentPromotionsData } from 'domains/promotion/Promotion.store';
import { getUserCountry, getUserRights, UserRole } from 'domains/user';
import { ACCOUNT, LANGUAGE, SETTINGS } from 'pages/ProfilePage/ProfilePage.types';
import { theme } from 'styles';
import { Badge, Icon, IconType, MarginBox, Text, WithTooltip } from 'UI';
import { getCondArrayItem, getData, hasUserAnyRight } from 'utils';
import {
  TRACKING_EVENT_GO_TO_ESTIMATE_VIA_MENU,
  TRACKING_EVENT_ORDERS_IN_PROGRESS,
} from 'utils/eventTracker/EventTracker.types';
import { Country } from 'utils/i18n/Country';
import { IconWrapper, MenuItem, SideMenuScrollWrapper } from './PageSideBar.styled';

const { Sider: AntSider } = Layout;

export const SIDEBAR_WIDTH = 250;
export const SIDEBAR_WIDTH_COLLAPSED = 90;

// eslint-disable-next-line react/display-name
const menuIcon = (icon: IconType) => (props: IconComponentProps) => (
  <Icon IconComponent={icon} size={props.size} display={'block'} mr={'auto'} ml={'auto'} color={props.color} />
);

export function EstimateIconComponent(props: IconComponentProps) {
  const { t } = useTranslation();
  const currentEstimateId = useSelector(getCurrentEstimateId);
  const rowsCount = useSelector((state: RootState) => getRowTablesCount(state, currentEstimateId));
  return (
    <PrivateComponent
      render={() => (
        <MarginBox mx={'auto'}>
          <Badge size={props.badgeSize} count={rowsCount} offset={props.badgeOffset} borderColor={'black'}>
            <WithTooltip title={props.disableTooltip ? '' : t('estimate', 'Estimate')} placement={'bottom'}>
              <Icon
                IconComponent={FileAltIcon}
                size={props.size}
                display={'block'}
                color={props.color}
                hoverFill={props.hoverFill}
              />
            </WithTooltip>
          </Badge>
        </MarginBox>
      )}
      requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
    />
  );
}

interface IconComponentProps {
  size: number;
  color: string;
  badgeSize: number;
  badgeOffset: [number | string, number | string];
  icon?: IconType;
  hoverFill?: string;
  disableTooltip?: boolean;
}

type MenuItemsType = {
  label: string;
  MenuIcon: FunctionComponent<IconComponentProps>;
  link: string;
  requiresVehicle?: boolean;
  onMouseEnter?: (t: TFunction, vehicleKey?: string) => void;
  onDisabledClick?: (t: TFunction, vehicleKey?: string) => void;
  additionalAction?: () => void;
  dataCy?: string;
};

const getMenuItems = (t: TFunction, userRights: UserRole[]) => {
  const params = new URLSearchParams(location.search);
  params.set(EstimateTabParam, EstimateTabName);
  const menuItems: (MenuItemsType | boolean)[] = [
    { label: t('common.home', 'Home'), MenuIcon: menuIcon(HomeIcon), link: ROUTER_HOME, dataCy: 'tab-home' },
    {
      label: t('profile', 'Profile'),
      MenuIcon: menuIcon(UserIcon),
      link: ROUTER_ACCOUNT,
      dataCy: 'tab-profile-settings',
    },
    {
      label: t('catalog.history', 'Search History'),
      MenuIcon: menuIcon(ClockIcon),
      link: ROUTER_HISTORY,
      dataCy: 'tab-search-history',
    },
    ...getCondArrayItem(
      hasUserAnyRight([UserRole.COMMAND, UserRole.CONNECT_COMMANDE], userRights) && {
        label: t('order.my_orders', 'My Orders'),
        MenuIcon: menuIcon(FolderOpenRegularIcon),
        link: ROUTER_ORDERS,
        additionalAction: () => trackAppEvent(TRACKING_EVENT_ORDERS_IN_PROGRESS),
        dataCy: 'tab-my-orders',
      },
    ),
    ...getCondArrayItem(
      hasUserAnyRight([UserRole.COMMAND, UserRole.CONNECT_COMMANDE], userRights) && {
        label: t('estimate', 'Estimate'),
        MenuIcon: EstimateIconComponent,
        link: `${ROUTER_ESTIMATE}?${params}`,
        additionalAction: () => trackAppEvent(TRACKING_EVENT_GO_TO_ESTIMATE_VIA_MENU),
        dataCy: 'tab-estimate',
      },
    ),
  ];
  return menuItems as MenuItemsType[];
};

const TopHeaderLinks = ({ collapsed, isActive }: { collapsed: boolean; isActive: (link: string) => boolean }) => {
  const { t } = useTranslation();
  const country = useSelector(getUserCountry);
  const promotionsData = useSelector(getCurrentPromotionsData);
  const showCurrentPromotions = promotionsData?.length > 0;
  const showTires = Country.canShowTiresByCountry(country);
  const linkItems = getLinkItems(t, showTires, showCurrentPromotions);

  return (
    <>
      {!collapsed && (
        <MarginBox mr={50}>
          <Text type={'h5_bold'} disableGutter>
            {t('sidebar.shop_by_department.title', 'Shop by Department')}
          </Text>
        </MarginBox>
      )}
      {linkItems.map((item) => (
        <WithTooltip key={item.text} title={collapsed && item.text} placement={'right'}>
          <div>
            <RenderMenuItem
              collapsed={collapsed}
              label={item.text}
              MenuIcon={menuIcon(item.icon)}
              isSelected={isActive(item.link)}
              link={item.link}
              onClick={() => {
                return;
              }}
            />
          </div>
        </WithTooltip>
      ))}
    </>
  );
};

export interface PageSideBarProps {
  collapsed: boolean;
  opened: boolean;
  activeUrl: string;
}

interface RenderMenuProps {
  collapsed: boolean;
  label: string;
  MenuIcon: FunctionComponent<IconComponentProps>;
  onClick: () => void;
  isSelected: boolean;
  link: string;
  isDisabled?: boolean;
  onDisabledClick?: () => void;
  onMouseEnter?: () => void;
  dataCy?: string;
}

const RenderMenuItem = ({
  collapsed,
  label,
  MenuIcon,
  onClick,
  isSelected,
  link,
  isDisabled,
  onDisabledClick,
  dataCy,
}: RenderMenuProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (!isDisabled) {
      if (onClick) {
        onClick();
      }
      if (link) {
        history.push(link);
      }
    } else {
      if (!onDisabledClick) return;
      onDisabledClick();
    }
  };
  const collapsedItemTextType = isDisabled ? 'light_dimmer' : 'light_12_black_85';
  const itemTextType = isDisabled ? 'text_disabled' : 'text_dim';

  return (
    <MenuItem collapsed={collapsed} $active={isSelected} onClick={handleClick} data-cy={dataCy}>
      {collapsed ? (
        <MenuIcon size={24} color={theme.color.brand_black} badgeSize={17} badgeOffset={[-5, 24]} disableTooltip />
      ) : (
        <IconWrapper>
          <MenuIcon size={24} color={theme.color.brand_black} badgeSize={15} badgeOffset={[-5, 13]} disableTooltip />
        </IconWrapper>
      )}
      {!collapsed && (
        <Text className={'menu-item-title'} type={collapsed ? collapsedItemTextType : itemTextType} cursor={'pointer'}>
          {label}
        </Text>
      )}
    </MenuItem>
  );
};

const MainMenuLinks = ({ collapsed, isActive }: { collapsed: boolean; isActive: (link: string) => boolean }) => {
  const { t } = useTranslation();
  const userRights = useSelector(getUserRights);
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const menuItems = getMenuItems(t, getData(userRights) ?? []);
  const [update, setUpdate] = useState<boolean>(false);

  return (
    <FirstHelpPopin streamId={ROUTER_HOME} popinId={`${ROUTER_HOME}_menu`} placement={'right'}>
      <ProfileInfo mini={collapsed} />
      {menuItems.map((item, key) => (
        <WithTooltip key={key} title={collapsed && item.label} placement={'right'}>
          <div>
            <RenderMenuItem
              collapsed={collapsed}
              label={item.label}
              MenuIcon={item.MenuIcon}
              onClick={() => {
                setUpdate(!update);
                item.additionalAction?.();
              }}
              isSelected={isActive(item.link)}
              link={item.link}
              isDisabled={item?.requiresVehicle && !vehicleKey}
              onDisabledClick={() => (item.onDisabledClick ? item.onDisabledClick(t, vehicleKey) : undefined)}
              dataCy={item.dataCy}
            />
          </div>
        </WithTooltip>
      ))}
    </FirstHelpPopin>
  );
};

const PageSideBar = ({ opened, collapsed, activeUrl }: PageSideBarProps) => {
  const vehicleDetail = useSelector(getLastVehicleDetail);

  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  const [touchEnabled, setTouchEnabled] = useState<boolean>(false);

  const setNewWindowHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    const enableTouchScrolling = () => {
      if (!touchEnabled) {
        setTouchEnabled(true);
      }
    };
    window.addEventListener('resize', setNewWindowHeight);
    window.addEventListener('touchstart', enableTouchScrolling);
    return () => {
      window.removeEventListener('resize', setNewWindowHeight);
      window.removeEventListener('touchstart', enableTouchScrolling);
    };
  }, [touchEnabled, setTouchEnabled]);

  const isActive = useCallback(
    (link: string): boolean => {
      let linkSubstring = link.split('?')[0];
      if (link === '/') {
        return activeUrl === link;
      }
      if (activeUrl.startsWith(ROUTER_PROFILE) && linkSubstring.includes(ACCOUNT)) {
        if (activeUrl.includes(LANGUAGE)) {
          linkSubstring = `${ROUTER_PROFILE}/${LANGUAGE}`;
        } else if (activeUrl.includes(SETTINGS)) {
          linkSubstring = `${ROUTER_PROFILE}/${SETTINGS}`;
        }
      }
      return activeUrl.startsWith(linkSubstring);
    },
    [activeUrl],
  );

  const menuWidth = opened ? (collapsed ? `${SIDEBAR_WIDTH_COLLAPSED}px` : `${SIDEBAR_WIDTH}px`) : '0';
  const menuHeight = collapsed ? '100vh' : `${windowHeight - HEADER_HEIGHT + 1}px`;

  return (
    <AntSider width={menuWidth}>
      <SideMenuScrollWrapper
        width={menuWidth}
        height={menuHeight}
        touchEnabled={touchEnabled}
        offset={HEADER_HEIGHT}
        opened={opened}
      >
        <MainMenuLinks collapsed={collapsed} isActive={isActive} />
        <TopHeaderLinks collapsed={collapsed} isActive={isActive} />
        {!collapsed && vehicleDetail && <SideBarCarCatalog vehicleDetail={vehicleDetail} />}
      </SideMenuScrollWrapper>
    </AntSider>
  );
};

export default PageSideBar;
