import React from 'react';
import { Modal as AntModal } from 'antd-v5';
import styled from 'styled-components';
import { MarginBox } from 'UI/Box';
import { useBreakpointSelectorFull } from 'utils';

export const SModal = styled(AntModal)<{ backgroundColor?: string }>`
  .ant-modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .ant-modal-footer {
    border-top: 0;
  }

  .ant5-modal-content {
    background-color: ${({ backgroundColor }) => backgroundColor ?? ''};
  }

  padding: 15px 0;
`;

export interface CustomModalProps {
  children?: React.ReactNode;
  title?: React.ReactNode | string;
  open?: boolean;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  footer?: React.ReactNode;
  width?: string | number;
  closable?: boolean;
  mask?: boolean;
  maskClosable?: boolean;
  className?: string;
  zIndex?: number;
  backgroundColor?: string;
  getContainer?: HTMLElement | string;
  style?: React.CSSProperties;
}

export const Modal = ({
  children,
  title,
  open,
  onCancel,
  footer,
  width,
  closable = false,
  mask,
  maskClosable = true,
  className,
  zIndex = 9000,
  backgroundColor,
  getContainer,
  style,
}: CustomModalProps) => {
  const defaultWidth = useBreakpointSelectorFull()({ xs: 720, sm: 720, md: 720, lg: 924, xl: 1340, xxl: 1820 });
  return (
    <SModal
      title={title}
      open={open}
      onCancel={onCancel}
      footer={footer}
      width={width ?? defaultWidth}
      zIndex={zIndex}
      backgroundColor={backgroundColor}
      centered
      closable={closable}
      className={className}
      destroyOnClose
      mask={mask}
      maskClosable={maskClosable}
      getContainer={getContainer}
      style={style}
    >
      <MarginBox mx={20}>{children}</MarginBox>
    </SModal>
  );
};
