import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

export const SCenter = styled.div`
  text-align: center;
`;

export const SideMenuScrollWrapper = styled.div<{
  width: string;
  height: string;
  touchEnabled: boolean;
  offset: number;
  opened: boolean;
}>`
  overflow: ${({ touchEnabled }) => (touchEnabled ? 'auto' : 'hidden')};
  position: fixed;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  transition: all 0.2s;
  top: ${({ offset }) => `${offset}px`};
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  scrollbar-gutter: stable;
  z-index: 1025;
  background-color: ${theme.color.white};
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);

  :hover {
    overflow: auto;
  }
`;

const activeCSS = css`
  background-color: ${theme.color.brand_black};

  .menu-item-title {
    color: ${theme.color.white};
  }

  .icon {
    fill: ${theme.color.white};
  }

  cursor: pointer;
`;

const hoverCSS = css`
  background-color: ${theme.color.grey95_light_120};
  cursor: pointer;
`;

const submenuCSS = css`
  padding: 0 10px 0 10px;
  width: 225px;
`;

export const MenuItem = styled.div<{
  $active?: boolean;
  $hoverActive?: boolean;
  collapsed?: boolean;
  submenu?: boolean;
}>`
  flex: 0 0 auto;
  height: ${({ collapsed }) => (collapsed ? '40px' : '44px')};
  width: ${({ collapsed }) => (collapsed ? '45px' : '220px')};
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;

  ${({ $active }) => $active && activeCSS};
  ${({ $hoverActive }) => $hoverActive && hoverCSS};
  ${({ submenu }) => submenu && submenuCSS};
`;

export const CategoriesWrapper = styled(Flex)`
  padding: 40px;
`;

export const CategoryCard = styled(Flex)`
  flex: 0 0 auto;
  width: 300px;
  padding: 15px 20px 15px 20px;
  background-color: ${theme.color.white};
  border-radius: 10px;
`;

export const Level3Category = styled(Flex)`
  flex: 0 0 auto;
  min-height: 30px;
  max-height: 44px;
  padding: 8px;
  border-radius: 8px;

  span {
    line-height: 20px;
  }

  :hover {
    cursor: pointer;
    background-color: ${theme.color.grey95_light_120};
  }
`;

export const IconWrapper = styled.div`
  margin: 0 15px 0 15px;
  float: left;
`;
